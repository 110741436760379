@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com);
@import url(https://fonts.gstatic.com);
@import url(https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;1,700&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css);
/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
	width:75px;
  transition: width .25s ease-in-out;

}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

/* #sidebar-wrapper .list-group { */
  /* width: 15rem; */
/* } */

/* #page-content-wrapper {
  min-width: 100vw;
} */
#page-content-wrapper {
  min-width: 0;
  width: 100%;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
   width:75px;
  }



  #wrapper.toggled #sidebar-wrapper {
    width:250px;
  }
}

.sidebar-list{
  position: relative;
  overflow: hidden;
}
.sidebar-list:hover{
  overflow: visible;
}
 .sidebar-link{
  display: flex;
  flex-wrap: nowrap;
  padding: 15px 20px;
  width: 75px;
  position: relative;
  z-index: 99;
  text-align: center;
}
.sidebar-link i{
font-size: 24px;
position: absolute;
transition: 0.3s ease-out;
z-index: 99;
color: #d4dee8;
width: 30px;
}
.sidebar-link span{
  font-size: 14px;
  opacity: 1;
  transform: translateX(65px);
  -webkit-transform: translateX(65px);
  white-space: nowrap;
  padding-left: 0px;
  opacity: 0;
}

.sidebar-list:hover .sidebar-link{

  width:210px;
  background: #f1f1f1;
}

.sidebar-list:hover .sidebar-link span{
  opacity: 1;
}

.toggled .sidebar-link{
  justify-content: left;
}
.toggled .sidebar-link i{
font-size: 18px;
width:25px;
text-align: center;

}
.toggled .sidebar-link span{
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  padding-left: 30px;
  opacity: 1;

}

.sidebar-link:hover,.sidebar-link:focus,.sidebar-link:hover i,.sidebar-link:focus i{
  color:#6c63ff;
}
.sidebar-dropdown{
  transform: translateX(45%);
  -webkit-transform: translateX(45%);
  position: absolute;
  left: 0px;
  background: #fff;
  /* padding: 0px 10px ; */
  width: 145px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  padding: 15px 0px 15px 30px;
  z-index: 999;
}

.sidebar-list:hover .sidebar-dropdown{
  opacity: 1;
}
.sidebar-dropdown-link{
  font-size: 14px;
  padding: 10px 0px;
  display: inline-block;
  transition: 0.3s ease-in-out;
  width:100%;
}
.sidebar-dropdown-link:hover{
  color: #8195ab;
}

.toggled .sidebar-list  .sidebar-dropdown
{
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.toggled .sidebar-list:hover  .sidebar-dropdown{
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  width:100%;
  padding-left: 65px;
  padding-right: 0px;
  position: relative;
}
/* head bar */

.name-block{
  text-align: right;
  padding: 0px 25px;
  font-size: 14px;
  color: #8195AB;
}
.avatar-md{
  width: 65px;
  /* height: 65px; */
  padding: 0px;
}
/* .header-profile-user{
  max-width:100%;
  padding: .25rem;
    background-color: #f2f2ff;
    border: 1px solid #f2f2ff;
} */

.header-profile-user {
    max-width: 85%;
    padding: 0.25rem;
    background-color: #f2f2ff;
    border: 1px solid #f2f2ff;
    height: 50px;
}


html body{
    background: #f1f4f9;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
}
p {
    font-family: 'Roboto', sans-serif;
}
nav {
    font-weight:bold;
}
.bgblue {
    background:#e2eff7;
}
.bgblack {
    background:#181B22;
}
.bgtheme-header{
    background-color: rgba(15, 40, 62, 0.5);
}
#myHeader{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
}
#myHeader nav{
    padding: 5px 15px;
}
#header h1 {
    text-transform:uppercase;
    font-weight:800;
     font-family: "Raleway", sans-serif;
    font-size:54px;
}

@media(min-width:767px){
    #header h1 {
        font-size:42px;
    } 
}
@media(min-width:990px){
    #header h1 {
        font-size:54px;
    } 
}
#header h1 .text-theme-gradient{
    display: block;
}
@media(max-width:767px){
    #header h1 {
        font-size:24px;
    } 
}
.text-theme-gradient{
    color: #004493;
    background: -webkit-linear-gradient(#0081aa, #004493);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.textblue {
    color:#38b6ff;
}
.textwhite {
    color:#ffffff !important;
}
.textred {
    color:#dd1f24 !important;
}
div#header {
    padding: 50px 0;
    background-image: url(/static/media/car_wash.3a5259ea.jpg); 
    position: relative;
    z-index: 1;
    background-size: cover;
    min-height: 100vh;
    align-items: center;
    display: flex;
    background-position: 38% top;
    background-repeat: no-repeat;
}
div#header::before{
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}
.header_right {
    position:relative;
}
.header_rightimg {
    max-width: 100%;
}
a.btn.home.learn_more .fa-long-arrow-right {
    color:#fff !important;
    font-size:24px !important;
}
/* a.btn.home.learn_more {
    background: linear-gradient(45deg, #dd2021, #971e1e);
    border-radius: inherit;
    font-weight: bold;
    max-width: 400px;
    color: #ffffff;
    margin-right: 15px;
    /* border-radius: 5px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    border: 1px solid transparent;
    box-shadow: 5px 5px 0px #d9202169;
    border-right: transparent;

} */
.btn-theme{
    background: linear-gradient(45deg, #7d222e, #e81f1e);
    border-radius: inherit;
    font-weight: bold;
    max-width: 180px;
    color: #ffffff;
    margin-right: 15px;
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    text-transform: uppercase;
    border-right:1px transparent; 
    
    border: 1px solid rgba(255,255,255,0.3);
    box-shadow: 5px 5px 0px #d9202169;
    transition: 0.3s ease-in-out;
    padding: 10px 20px;
    font-size: 14px;
}
.btn-theme:hover,.btn-theme:focus{
    color: #fff;
    border:1px solid #fff;    
    box-shadow: 0px 0px 0px #d9202169;
    background: #000;
}
.rightarrow {
    margin-left:15px;
    vertical-align:middle;
    font-size: 24px;
    margin-top: -5px;
}
.header_buttons {
    padding-top:20px;
}
.what_we_do {
    position: relative;
    /* margin-top: 250px; */
}
.what_we_do h1 {
    font-size:14px;
    font-weight:bold;
}
.what_we_do h2 {
    font-size:24px;
    text-transform:uppercase;
    font-weight:bold;
}
.what_we_do h3 {
    font-size:24px;
    text-transform:uppercase;
    color:#dd1f24;
    font-weight:bold;
}
.what_we_do .future-content img {
    background: #ffffff;
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #ff3c1d;
    padding: 15px;
    margin-bottom: 20px;
}
.row.repairing {
    padding: 50px 0;
}
.row.repairing2 {
    padding: 100px 0;
}
.future-content {
    border: 1px solid #ccc;
    margin-bottom: 30px;
    padding: 50px;
    border-radius: 5px;
    background: #343434;
}
.future-content img{
    transition: 0.5s ease-in-out;
    transform: rotateY(0deg);
}
.future-content:hover img{
    transform: rotateY(360deg);
}
.future-content h1 {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    text-transform:capitalize;
}
.future-content h2 {
    font-size: 16px;
    color: #fff;
    font-weight:normal;
    text-transform:capitalize;
}
.future-content:hover {
    background:#ff3c1d;
}
.flexible {
    /* background : #01004c; */
    /* padding:50px 0; */
    background: url(/static/media/open-car.6fb24981.jpg);
    background-size: cover;
    background-position: center;
}
.flexible-container {
    background : #181B22;
    padding:50px 0;
    /* opacity: 0.91; */
}
.flexible h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
}
.repair_box {
    background: #004f97;
    margin-bottom: 40px;
    border-radius: 10px;
    padding: 25px;
    min-height: 180px;
}
.flexible_right img {
    background: #921e23;
    padding: 5px;
    border-radius: 10px;
}
.repair_box h2 {
    font-size: 17px;
    color: #fff;
    font-weight: bold;
}
.repair_box p {
    color: #fff;
    font-size: 15px;
    margin-top: 15px;
}
.flexible_right {
    position:relative;
}
.flexible_image {
    position: absolute;
    right: 8%;
    height: 35em;
    width: 33em;
}
.gallery {
    position:relative;
    margin-top:170px;
}
.gallery h1 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
}
.gallery h2 {
    font-weight: bold;
    line-height:30px;
    font-size: 16px;
}
.company {
    background: #e2eff7;
    color:#000;
    background-position: center;
    background-size: cover;
    /* color: #ffffff;   */
}
.company >.company-sub {
    /* background: #181b22; */
    opacity: 0.91;
    height: inherit;
    padding: 50px 0;
}
.company h1 {
    /* color: #ffffff; */
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    line-height:40px;
}
ul.company_list {
    list-style-type: none;
    margin-left: 0px;
    /* margin-top: 20px; */
    margin-bottom:40px;
}
@media(min-width:990px){
    ul.company_list{
        margin-left: 62px;
    }
}
ul.company_list li {
    padding: 10px 0;
    display: flex;
    align-items: flex-start;

}
ul.company_list li i {
    color: #dd1f24;
    background: #fff;
    margin-right: 15px;
    font-size: 16px;
    
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #dd1f24;
}
a.btn.contact_us {
    background: #dd1f24;
    border-radius: inherit;
    font-weight: bold;
    max-width: 300px;
    margin-left: 60px;
    color:#fff;
}
a.btn.contact_us .fa-long-arrow-right {
    color:#01004c;
}
/* .col-md-5.company_right {
    margin-top: 100px;
} */
.company_right p {
    padding:15px 0;
}
.testimonials {
    background: #fff;
    padding: 50px 0;
    color:#000;
}
.testi_right {
    position: relative;
}
.testi_img {
    position: absolute;
    right: 50px;
}
.testi_image {
    position: absolute;
    right: 3%;
}
.testi_container {
    background: #f4f5f9;
    padding: 60px 50px !important;
    border-radius: 5px;
}
testi_container p {
    font-weight: bold;
    font-size: 15px;
    line-height: 23px;
}
span.testi_name {
    margin-top: 17px;
    font-weight: bold;
    font-size: 20px;
    float: left;
}
.testi_name img {
    width:80px;
}
.footer {
    background: linear-gradient(45deg, #0081aa, #971e1e);
    position: relative;
    padding-top: 50px;
    color:#fff;
    background-repeat:no-repeat;
    background-size:cover;
}
.footer:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background-image:url(/static/media/footer_bg.5f5dec98.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
.footer h1 {
    font-size: 20px;
    font-weight: bold;
    padding-bottom:15px;
}
ul.footer_links {
    list-style-type:none;
    padding-left: 0px;
}
ul.footer_links li {
    padding:10px;
}
ul.footer_links li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}
#copyright {
    padding:20px 0;
    color:#fff;
    border-top:4px solid #fff;
}
#copyright a {
    color:#fff;
    text-decoration:none;
}
.footer p {
    text-align:left;
}
.social_icons i {
    background: #fff;
    color: #000;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px;
    text-align:center;
    margin-right: 6px;
    width: 40px;
}
/* .modal-login-body{
    padding: 0 0 0;
}
.modal-content{
    border-radius: 0;
} */
.footer_content {
    position: relative;
}
#header_inner {
    min-height: 300px;
    background:#181B22;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#header_inner a {
    color:#fff !important;
}
#header_inner a.nav-link.active {
    border:2px solid #fff;
}
h1.title_text {
    color:#fff;
    font-size:35px;
    text-align:center;
    padding-top:50px;
    font-weight:bold;
}

#header_inner:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 300px;
    opacity: 0.1;
    background: url(/static/media/ServiceHeader.5d390e2d.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
#trusted {
    padding:50px 0;
}
#trusted h1 {
    color: #181B22;
    font-size: 37px;
    line-height: 47px;
}
#trusted h2 {
    color: #202020;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 0;
}
#trusted p {
    color: #3f3f3f;
    font-weight: bold;
    margin-bottom:15px;
}
#trusted span {
    font-weight: bold;
}
a.discover_more {
    background: #dd1f24;
    color: #fff;
    text-decoration: none;
    padding: 10px;
}
a.contact_us {
    font-weight: bold;
    color: #dd1f24;
    margin-left: 45px;
}
a.contact_us i.fa.fa-long-arrow-right.rightarrow {
    color: #dd1f24 !important;
    margin-left:4px;
}
img.mechanic_training {
    width: 100%;
    position: relative;
    z-index: -9;
}
.mechanic_div {
    position: relative;
}
.py-equal{
    padding:100px 0px;
}
.ten_years {
    background: #dd1f24;
    color: #fff;
    padding: 1px;
    width: 164px;
    height: 100px;
    float: left;
    font-size: 21px;
    text-align: center;
    margin-left: -43px;
    bottom:-24px;
    right:115px;
    position:absolute;
}
.ten_years_border {
    width: 190px;
    position: absolute;
    bottom: -49px;
    border: 6px solid #d9d9d8;
    right: 102px;
    height: 158px;
    padding-top: 30px;
}
#feedback {
    padding:50px 0;
}
#feedback img {
    width:70px;
}
.border-right {
    border-right:3px dashed #dd1f24;
}
#feedback span {
    color: #01004c;
    font-size: 20px;
    font-weight: bold;
    margin-left: 15px;
    margin-top: 15px;
}
#feedback span.count {
    color: #000;
    font-size: 20px;
    font-weight: bold;
}
.feedbacks {
    display: flex;
    justify-content: center;
}
#team {
    padding:50px 0;
    min-height:490px;
}

.team1 {
    background: #181B22;
    color: #fff;
    padding: 30px 20px;
}
.team2 {
    background: #e2eff7;
    padding: 30px 20px;
   
}
@media(min-width:1200px){
    .team1 {
        background: #181B22;
        color: #fff;
        min-height: 300px;
       
        width:55% !important;
        opacity: 0.9;
    }
    .team2 {
        background: #e2eff7;
        width: 53% !important;
        float: right;
        position: absolute;
        right: 0;
        z-index: -9;
        padding-top: 50px !important;
        top: 143px;
        padding-left: 108px !important;
    }
}
.team1 h1 {
    color:#dd1f24;
    font-size:24px;
}
.team1 h1 span {
    font-weight:bold;
}
.team1 p {
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 20px;
}
.team2 h2 {
    font-size: 24px;
    color: #01004c;
    font-weight: bold;
}
a.visit_area {
    font-weight: bold;
    color: #fff;
    text-transform:uppercase;
}
a.visit_area i.fa.fa-long-arrow-right.rightarrow {
    color:#fff;
    vertical-align:top;
}
.team2 img {
    width:50px;
    margin-right: 15px;
}
.team2 p {
    color: #01004c;
    font-size: 40px;
    font-weight: bold;
}
.team2 span {
    margin-bottom: 50px;
    display: flex;
    padding-left: 68px;
    font-size:20px;
}
#skills .img-fluid{
    height: 30em;
}
#brands {
    margin-top:70px;
    padding:100px 0;
}
#brands h1 {
    color: #dd1f24;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 44px;
    padding-bottom: 30px;
}
#brands h2 {
    color: #000;
    font-size: 44px;
    padding-bottom: 30px;
}
#brands h2 span {
    font-weight: bold;
}
#founded {
    padding:50px 0;
}
.founded_list li {
    list-style-type: none;
    padding:15px 0;
    text-align:center;
    cursor:pointer;
}
.founded_list li i {
    font-size:50px;
}
#history h1,#mission h1,#vision h1 {
    font-size: 28px;
    font-weight: bold;
    padding-top: 15px;
}
#history p,#mission p,#vision p {
    color: #000;
    padding: 10px 0;
    line-height: 23px;
}
#history ul,#mission ul,#vision ul {
    margin-left:15px;
}
#history ul li,#mission ul li,#vision ul li {
    padding:5px 0;
}
#skills {
    padding:50px 0;
    background:#262b30;
    color: #ffffff;
}
#skills h1 {
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 15px;
}
#skills span {
    border-bottom: 4px solid #ffffff;
    font-weight: normal;
}
#skills p {
    padding-top:20px;
    font-weight:bold;
}
.progress {
    background-color: #d9d9d8 !important;
    border-radius: 0 !important;
    height:10px !important;
}
.progress-bar {
    background-color: #dd1f24 !important;
    height:10px !important;
}
#skills h2 {
    padding-top: 33px;
    font-size: 24px;
}
/* a.learn_more {
    margin-top:30px;
    background: #dd1f24;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    float:left;
} */
.learn_more i.fa.fa-long-arrow-right.rightarrow {
    color: #fff;
}
#working_time {
    padding:50px 0;
    background: #ffffff;
}
.working_image {
    position:relative;
}
.working_image img {
    width:90%;
    height: 25em;
}
.yellow_border {
    width: 255px;
    position: absolute;
    bottom: -40px;
    border: 6px solid #dd1f24;
    right: 5px;
    height: 267px;
    /* padding-top: 0px; */
}
#working_time h1 {
    color: #dd1f24;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
}
#working_time h2 {
    font-weight: bold;
    padding: 7px 0;
}
#working_time h3 {
    font-weight:bold;
}
#working_time ul {
    margin-top:20px;
}
#working_time ul li {
    list-style-type: none;
    font-weight: bold;
    font-size: 20px;
    width: 70%;
    padding: 5px 0;
}
#working_time ul li span {
    float: right;
}
#working_area {
    padding: 50px 0;
    background: #181b22;
    margin-top:30px;
    color: #ffffff;
}
#working_area h1 {
    color: #dd1f24;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
}
#working_area h2 {
    font-weight: bold;
    padding: 7px 0;
}
#working_area h3 {
    font-weight:bold;
}
#working_area p {
    padding:30px 0;
    font-weight:bold;
    /* max-width:350px; */
}
a.contact_usblue {
    background: #dd1f24;
    color: #fff;
    text-decoration: none;
    float: left;
    width: 150px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    position:absolute;
    bottom:0;
}
.working_area1 {
    position:relative;
}
#header_inner_explore {
    min-height: 100vh;
    position:relative;
    display: flex;
    align-items: center;
}
#header_inner_explore a {
    color:#01004c !important;
}
#header_inner_explore a.nav-link.active {
    border:2px solid #000;
}
#bgcolor:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.06;
    background: url(/static/media/explore_header.2ab08050.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-top-right-radius: 70px;
    border-bottom-right-radius: 70px;
}
#header_inner_explore h1 {
    color: #fff;
    text-align: left;
    position: relative;
}
#header_inner_explore h2 {
    color: #fff;
    position: relative;
    text-align: left;
    font-size: 15px;
}
a.get_appointment {
    margin-top: 30px;
    background: #fff;
    color: #000;
    text-decoration: none;
    padding: 10px;
    float: left;
    position: absolute;
    top: 53%;
    font-weight:bold;
}
#bgcolor {
    background: rgba(18, 5, 7, 0.8);;
    height: 100%;
    width: 100%;
    position: absolute;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: -1;
}
a.get_appointment i.fa.fa-long-arrow-right.rightarrow {
    color:#01004c;
}
#trusted_explore {
    padding:50px;
    background: #9b2632;
    color: #fff;
}
#trusted_explore img {
    width:70px;
    border-radius: 50%;
    background: #fff;
    padding: 5px;
    border: 1px solid #d22027;
    margin: 0px auto;
}
#trusted_explore h1 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    padding: 12px 0px;
}
#trusted_explore p {
    font-weight: bold;
    font-size: 16px;
}
/* #about {
    padding:50px 0;
} */
#about h1 {
    /* padding:50px 0; */
    text-transform:uppercase;
    color: #dd1f24;
}
.aboutbg {
    /* background: #01004c; */
    background: #181B22;
    position:relative;
    min-height:300px;
    z-index:-999;
}
.aboutbg:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image:url(/static/media/about_bg.88cebe29.jpg);
    background-repeat: no-repeat;
    background-position:center;
    z-index:-999;
    background-size: cover;
}
.aboutbg .img1 {
    position: absolute;
    bottom: 0;
    width: 283px;
    z-index:99,
}
.aboutbg .img2 {
    position: absolute;
    bottom: 0;
    right: -100px;
    z-index: -99;
    font-weight: bold;
}
a.get_in_touch {
    margin-top: 30px;
    background: #dd1f24;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    float: left;
    font-weight: bold;
    width: 120px;
    height: 50px;
    line-height: 29px;
    text-align: center;
}
.about2 {
    padding-left:100px !important;
}
#about h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    padding-bottom: 15px;
}
#about p {
    font-weight:bold;
    font-size: 16px;
}
#best {
    padding:50px 0;
}
.bestbg {
    background-color: #181B22;
    position: relative;
    min-height: 100vh;
    color:#ffffff;
    z-index: 1;
    background-repeat:no-repeat;
    background-size:cover;
}
.bestbg:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image:url(/static/media/footer_bg.5f5dec98.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
/* .best2 img {
    position: absolute;
    right: 0;
    bottom: 0;
} */ 
/* .best1 {
    position: absolute;
} */
.best1 h1 {
    font-size: 25px;
    text-transform: uppercase;
    padding-top: 45px;
    padding-left: 15px;
    font-weight: bold;
    color: #ffffff;
}
.best1 p {
    padding-left: 15px;
    font-weight: bold;
    color: #ffffff;
    font-size: 15px;
}
a.get_in_touch_white {
    margin-top: 30px;
    background: #dd1f24;
    color: #ffffff;
    text-decoration: none;
    padding: 10px;
    float: left;
    font-weight: bold;
    width: 120px;
    height: 50px;
    line-height: 29px;
    text-align: center;
    margin-left:15px;
}
#estimate {
    padding:0px 0;
    min-height: 100vh;
}
.estimate1 {
    background-color: #181B22;
    position: relative;
    color: #000;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.estimate1:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
    background-size: cover;
    background-image:url(/static/media/ServiceHeader.5d390e2d.jpg);
    background-repeat: no-repeat;
    background-position:center;
}
.estimate1 h1 {
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;

}
#estimate .row{
    min-height: 100vh;
    
}
.estimate2 h1 {
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
   
}
.estimate2 {
    background-color: #181B22;
    position:relative;
    padding:50px 5px;
    color:#000;
     z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.estimate2:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
    /* background-image:url("../images/auto-mechanic-640x230.jpg"); */
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
a#get_quote {
    color: #fff;
    text-decoration: none;
    border: 5px solid;
    padding: 13px;
    position:relative;
}
#estimate_service {
    padding:20px 0;
    background:linear-gradient(to right, #181B22 60%, #fff 40%);
    margin-bottom:50px;
    position:relative;
    color:#ffffff;
    opacity: 0.91;
}
#estimate_service h1 {
    color:#ffffff;
    font-size:25px;
}
img.yellow_contact {
    background: #fff;
    width: 60px;
    border-radius: 50%;
    height: 60px;
    padding: 9px;
    float:left;
    margin-right:15px;
}
#estimate_service p {
    color: #ffbe00;
    font-weight: bold;
    margin-top: 15px;
    line-height: 30px;
}
img.yellow_car {
    position: absolute;
    bottom: -72px;
    width: 467px;
    right: 155px;
}
.discover {
    background:#dd1f24;
    color:#ffffff;
    text-decoration:none;
    padding:10px;
}
.estimate_service2 {
    position: absolute;
    right: 200px;
    bottom: 25px;
}
#core {
    padding:50px 0;
}
#core h1 {
    color: #dd1f24;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 25px;
    padding:15px 0;
}
#core h2 {
    color: #000;
    font-weight: bold;
    font-size: 40px;
}
.core_icons {
    background:#000;
    color:#fff;
    float:left;
    margin-top:35px;
    min-height:112px;
    padding-top:15px;
    width: 100%;
    cursor:pointer;
}
.core_icons:hover {
    background:#dd1f24;
}
.core_icons img {
    width:50px;
}
.core_icons span {
    float: left;
    width: 100%;
    text-align: center;
    padding-top:15px;
}
.core_img {
    width:100%;
    height:400px;
}
#brand {
    padding: 100px 0;
    background: #181b22;
    color: #ffffff;
}
#brand h1 {
    color: #ffffff;
    font-weight: bold;
    font-size: 30px;
    padding: 20px 0;
}
#brand p {
    font-weight: bold;
    margin-bottom:50px;
}
img.brand_img {
    background: #dd1f24;
    width: 70px;
    border-radius: 50%;
    padding: 15px;
    float:left;
}
#brand span {
    float: left;
    vertical-align: middle;
    padding-top: 15px;
    margin-right: 15px;
    width: 23%;
    text-align: left;
    margin-left: 15px;
}
a.get_more {
    background: #dd1f24;
    float: left;
    clear: both;
    text-decoration: none;
    color: #fff;
    margin-top: 20px;
    padding: 15px;
    font-weight: bold;
}
img.blue_car {
    position: absolute;
    left: 0;
    width: 600px;
    top: 89px;
}
#header_inner_contact {
    height: 300px;
    background:#181B22;
    position:relative;
}
#header_inner_contact a {
    color:#fff !important;
}
#header_inner_contact a.nav-link.active {
    border:2px solid #fff;
}
h1.title_text {
    color:#fff;
    font-size:35px;
    text-align:center;
    padding-top:50px;
    font-weight:bold;
}
#header_inner.header_inner_contact:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 300px;
    opacity: 0.1;
    background: url(/static/media/Mobile_Mechanic.ac0352d2.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: #2e3239;
}
.contact_div {
    background:#181B22;
    position:relative;
    height:500px;
}
.contact_div:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    opacity: 0.1;
    background: url(/static/media/contact_bg1.7c15ca6b.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.contact_div_img {
    width: 550px;
    bottom: 45px;
    position: absolute;
    right: -60px;
}
.contact_div2 {
    padding-left:100px !important;
}
#reputation {
    padding:50px 0;
}
#reputation h1 {
    color: #181B22;
    font-size: 30px;
    line-height: 47px;
}
#reputation h2 {
    color: #202020;
    font-size: 20px;
    padding: 40px 0;
}
#reputation p {
    padding-top: 40px;
    color: #3f3f3f;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 18px;
}
#reputation span {
    font-weight: bold;
}
#help {
    padding:50px 0;
}
#help h1 {
    color: #dd1f24;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
}
#help h2 {
    font-size: 28px;
}
#help p {
    font-size: 28px;
    font-weight: bold;
}
#contact_form {
    padding-top:20px;
}
#contact_form input[type="text"],#contact_form input[type="email"] {
    margin-bottom: 25px;
    border-radius: 0;
}
#contact_form textarea {
    height: 250px;
    width: 100%;
    border: 1px solid #ced4da;
    padding:15px;
}
#contact_form_submit {
    background: #dd1f24;
    border-radius: 0;
    margin-top: 15px;
    border: none;
}
#faq {
    padding:50px 0;
}
#faq h1 {
    font-weight: bold;
    padding-bottom: 20px;
    color: #dd1f24;
    font-weight: 900;
}
#faq h2 {
    font-weight: bold;
    padding-bottom: 20px;
}
#faq h3 {
    color:#cfcfcf;
}
#chatbox {
    padding:50px 0;
}
.chatboxinner {
    background: #181B22;
    border-radius: 25px;
    padding: 15px;
    color: #ffffff;
}
img.img-fluid.chat {
    width: 60px;
    border-radius: 60px;
}
.chatboxinner h1 {
    font-size: 19px;
    font-weight: bold;
    padding: 10px 0;
}
.loginbody {
    padding:0px !important;
}
.loginbg {
    background: #2e3239;
    margin-top: -18px;
    margin-left: -5px;
    margin-bottom: -18px;
    border-radius:8px;
    padding-top:25px;
}
.logoclass {
    width: 100px;
    display: block;
    margin: 0px auto;
    margin-top: 40px;
    margin-bottom: 20px;
}
p.login_head {
    color:#181B22;
    padding-bottom:30px;
    font-size:20px;
    text-transform :capitalize;
    /* font-weight:bold; */
    text-align:center;
}
form#login_form input[type=text] {
    border: none;
    border-bottom: 3px solid #cecece;
   /* margin-bottom: 20px;
    */
    border-radius: 0;
}
form#login_form input[type=email] {
    border: none;
    border-bottom: 3px solid #cecece;
   /* margin-bottom: 20px;
    */
    border-radius: 0;
}
form#login_form input[type=date] {
    border: none;
    border-bottom: 3px solid #cecece;
   /* margin-bottom: 20px;
    */
    border-radius: 0;
}
form#login_form input[type=address] {
    border: none;
    border-bottom: 3px solid #cecece;
    margin-bottom: 20px;
    border-radius: 0;
}
form#login_form input[type=text]:after {
    font-family: 'FontAwesome';
    content: '\f023';
    position:absolute;
    right: 6px;
}
form#login_form input[type=password] {
    border: none;
    border-bottom: 3px solid #cecece;
   /* margin-bottom: 20px;
    */
    border-radius: 0;
}
.btn-close-popup{
    width: 50px;
    background: #dd1f24;
    color: #fff;
    height: 39px;
    font-size: 20px;
    border: none;
}
input.btn.float-right.login_btn {
    width: 120px;
    background: #dd1f24;
    color: #fff;
    border-radius: 0;
    margin: 0 auto;
    display: block;
    height: 40px;
    font-weight: bold;
}
a.forgot {
    color: #b9b9b9;
    text-decoration: none;
    font-weight: bold;
    float: left;
    width: 100%;
    text-align: right;
    margin-bottom: 15px;
}
form#login_form {
    padding: 25px;
}
.signup_text {
    color:#dd1f24;
    font-weight:bold;
}
.signin_text {
    color:#dd1f24;
    font-weight:bold;
}
form#signup_form input[type=text],form#signup_form input[type=email],form#signup_form input[type=password] {
    border: none;
    border-bottom: 3px solid #cecece;
    margin-bottom: 20px;
    border-radius: 0;
}
form#signup_form {
    padding: 25px;
}
iframe {
    width:100%;
}
.navbar-brand{
    width: 120px;
}
.navbar-brand img {
    /* position:absolute; */
    max-width: 100%;
    z-index:999;
    width: auto;
    height: auto;
    /* margin-top: -70px;
    margin-left: -20px; */
}
.menu-element li a{
    font-size: 14px;
    font-weight: 400;
    border-bottom: 2px solid transparent;
    margin: 0px 10px;
}
.menu-element li a:hover,.menu-element li a:focus{
    border-bottom: 2px solid rgba(255, 137, 137, 0.5);
}
.menu-element li a.active{
    border-bottom: 2px solid #cc2021;
}

.map_border {
    border:4px solid #ffffff !important;
    height:330px;
}
.borderline {
    border-top: 2px dashed #fff !important;
    height: 179px;
    width: 400px;
    position: absolute;
    right: 235px;
    border-bottom: 2px dashed #fff !important;
    border-left: none !important;
    border-right: none !important;
    top: 31px;
}
/* a.contact_usblue:hover,a.learn_more:hover,a.discover_more:hover,a.get_in_touch:hover,a.get_more:hover{
    color: #fff;
    opacity: 0.5;
} */
/* a.contact_us:hover {
    color: #dd1f24;
    opacity: 0.5;
} */
a.get_appointment:hover {
    color: #01004c;
    opacity: 0.5;
}
a.get_in_touch_white:hover,a.discover:hover {
    opacity: 0.5;
}
.explore_img {
    position:relative;
    border-radius:15px;
    width: 100%;
}
/* .container.testi_container {
    margin-bottom: 210px;
} */
i.fa.fa-history.active,i.fa.fa-bullseye.active,i.fa.fa-eye.active {
    color:#01004c;
}
#vision,#mission {
    display:none;
}

/* span.what-we-do-item {
    display: inline-flex;
} */

/* media queries */
@media (max-width:480px) {
    .container.testi_container{
        margin-bottom:0px;
   }
    .testi_img {
        position: relative;
        right: 0;
   }
    .header_rightimg {
        position: relative;
        right:0%;
        width: 38em;
        height: 35em;
   }
    .what_we_do {
        margin-top: 0px;
   }
    .contact_div_img {
        right:0px;
   }
    .team1 {
        width:100% !important;
   }
    .team2 {
        float: left;
        width: 100% !important;
        position: relative;
        top: 0;
        padding-left: 0px !important;
   }
    .aboutbg .img2 {
        right:0px;
   }
    .estimate1,.estimate2 {
        width:100% !important;
        margin-left:0% !important;
   }
    a#get_quote {
        border: 5px solid;
        padding: 5px;
   }
    img.blue_car {
        position: relative;
        width: 100%;
   }
}
.pac-container{
    z-index: 99999;
}
p.error{
    color: red;
}
.input-group.form-group{
    margin-bottom: 10px;
}
.image-boundry-img{
    width: 100%;
    height: 100%;
}
.image-boundry{
    min-height: 180px;
    height: 185px;
    max-height: 185px;
}
.list-overflow{
    height: 80vh;
    overflow: auto;
    overflow-x: hidden;
}
.list-active{
    background-color: #ccc;
}
ul.list-element.cursor-pointer {
    list-style: none;
    padding-right: 20px;
}

.float-mechanic-img{
    margin-top: 60px;
}
.otp input{
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    margin-right: 5px;
}
.btn-main-with-hover{
    color: #00004e !important;
    border: 1px solid #00004e;
}
.btn-main-with-hover:hover{
    color: #fff!important;
    background-color: #00004e;
    border: 1px solid #00004e;
}
.btn-main-submit{
    color: #fff !important;
    border: 1px solid #00004e;
    background-color: #00004e;
}
.card-body.card-body-answer {
    padding-left: 15px;
}
.contactus-heading{
    color: #dd1f24;
    font-weight: 900;
}

h3.about-direcotor {
    color: #000000;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
}

.qrgenerator{
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    padding: 5px;
    background: #ffffff;
}

.qr-component .qrgenerator{
    position: absolute;
    top: 40px;
    left: 88px;
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
}

.our-app-qr{
    margin: 0 auto;
    margin-top: 0px;
    position: relative;
    width: 100px;
    max-width: 100px;
}
.qr-heading{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 0px;
}

.wrapper-download-btn{
    margin: 0 auto;
    width: 180px;
    position: relative;
}

.btn-wrapper{
    text-align: center;
}

#trusted-download h2 {
    color: #202020;
    font-size: 30px;
    font-weight: bold;
    padding: 5px 0;
    position: relative;
}

.qr-fragment-wrapper{
    background:#f1f4f9;
}


.download-btn{
    width: 180px;
    max-width: 180px;
    padding-bottom: 15px;
    padding-top: 15px;
}

#app-download_banner2 {
    height: 200px;
    /* background:#181B22; */
    position:relative;
    text-align: center;
}

#app-download_banner2:before {
    /* content: ' '; */
    display: block;
    /* position: absolute; */
    /* left: 0;
    top: 0; */
    width: 100%;
    height: 200px;
    opacity: 0.1;
    /* background: url('../images/ServiceHeader.jpg'); */
    background-repeat: no-repeat;
    background-position: center center;
    /* background-size: cover; */
}


div.title_text{
    font-size: 30px;
    font-weight: bold;
    color: #e02020;
}

button.user-selector-btn{
    background-color: #ffffff;
    border: none;
    color: #000000;
    padding: 15px 42px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
    font-weight: bold;
    min-width:200px;
}

button.user-selector-btn:hover{
    background-color: #dd1f24;
    /* background-color: #e02020; */

}



/* apps page css */
#apps {
    padding:50px 0;
}
.apps_first,.apps_second,.apps_third {
    position:relative;
    width: 100%;
    display: flex;
}
.apps_div h1 {
    color: #DD1F24;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    margin-bottom: 15px;
}
.apps_div h2 {
    color: #202020;
    font-size: 20px;
}
ul.apps_list {
    float: left;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 30px;
    width:100%;
}
.apps_first .apps_div_right {
    overflow: inherit;
}
.apps_div_right {
    text-align: center;
    overflow: hidden;
    position: inherit;
}
.apps_div_right::after {
    background: url(/static/media/81634-removebg-preview.9bd2d599.png) no-repeat;
    background-size: contain;
    background-position: center 20%;
    transform: rotate(10deg);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
}
.apps_right_img {
    position: static;
    width: 250px;
    right: 0;
    top: 0;
    transform: rotate(3deg);
}
.apps_right_img1 {
    width: 300px;
    left: -95px;
    bottom: 28px;
    transform: rotate(10deg);
    z-index: 9;
    position: absolute;
    animation: mover 1s infinite  alternate;
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
.apps_first {
    padding:80px 0;
}
.apps_right_img2 {
    position: relative;
    width: 250px;
    right: 0;
    top: 0;
    transform: rotate(357deg);
}
.apps_second {
    padding:80px 0;
}
.apps_third {
    padding:80px 0;
}
.apps_right_img3 {
    position: static;
    width: 250px;
    right: 0;
    top: 0;
    transform: rotate(3deg);
}
.apps_div p {
    font-size:15px;
    margin-bottom:15px;
    font-family: inherit;
}
.apps_right_img4 {
    position: absolute;
    width: 439px;
    right: 0;
    top: 23%;
    transform: rotate(10deg);
    z-index: -9;
    display: none;
}

.input-group-resend {
    text-align: end;
    /* position: absolute; */
    /* right: 30px; */
}

#filled-in-email,#filled-in-phone{
    margin-right: 4px;
}

.select-phone{
    margin-right: 14px;
}

.input-group-resend label {
    cursor: pointer;
}
.qr-list-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.qr-list-section .qr-component {
    width: 120px;
}
.qr-list-section .apps_list {
    flex: 1 1;
}
.qr-list-section .qr-component .qrgenerator{
    width: 120px;
    height: 120px;
    max-width: 120px;
    max-height: 120px;
    position: initial;
}
.progressed,
.remain div,
.remain {
	position: absolute;
	border-radius: 50%;
	left: 0;
	bottom: 0;
    margin: auto;
	-webkit-animation-timing-function: linear;
	animation-direction: normal;
	/* Safari 4.0 - 8.0 */
	animation-duration: 2s;
	animation-iteration-count: infinite;
    z-index: -1;
}

.progressed {
	/* Safari 4.0 - 8.0 */
	animation-name: progressAnim;
}

/* Safari 4.0 - 8.0 */


/* Standard syntax */

@keyframes progressAnim {
	from {
		width: 0;
		height: 0;
		background: rgb(119, 116, 116);
	}
	to {
		width: 150px;
		height: 150px;
		background: #dd1f24;
	}
}

.remain div {
	/* Safari 4.0 - 8.0 */
	animation-name: remainAnim;
}

.remain {
	transform-origin: 80px 5px;
	border-radius: 0;
	border: 0;
	width: 150px;
	height: 150px;
	text-align: center;
	/* Safari 4.0 - 8.0 */
	animation-name: remainContAnim;
}


/* Safari 4.0 - 8.0 */


/* Standard syntax */

@keyframes remainContAnim {
	from {
		transform: rotateZ(0);
	}
	to {
		transform: rotateZ(90deg);
	}
}


/* Safari 4.0 - 8.0 */


/* Standard syntax */

@keyframes remainAnim {
	from {
		width: 150px;
		height: 150px;
		background: #dd1f24;
	}
	to {
		width: 0;
		height: 0;
		background: #000;
	}
}

@media (max-width: 1024px) {
    .apps_div_right::after{
        transform: none;
    }
}
@media (max-width:991px) {
    .apps_right_img1{
        left:-85px;
    }
}
@media(max-width:767px){
    .apps_first,.apps_second,.apps_third {
        flex-direction: column;
        padding: 40px 0;
    }
    .apps_right_img1{
        left:30px;
    }
    .apps_first .apps_div_right {
        overflow: hidden;
    }
    .progressed,
    .remain div,
    .remain{
        top: 0;
        bottom: auto;
    }
}
@media (max-width:400px) {
    .apps_right_img1{
        width: 230px;
    }
    .apps_right_img,
    .apps_right_img2,
    .apps_right_img3{
        width: 200px;
    }
    .qr-list-section {
        flex-direction: column;
    }
}

@media(max-width:767px){
    .menu-element{
        margin-top: 20px;
    }
    .row{
        margin-left: 0px;
        margin-right: 0px;
    }
    #trusted h1{
        font-size:24px;
        line-height: normal;
    }
}

.navbar-toggle-line{
    width: 25px;
    height: 2px;
    background-color: #fff;
    display: block;
    opacity: 1;
    margin-bottom: 5px;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
}

.navbar-toggle-line.rotate-0{
    opacity: 0;
}
.navbar-toggle-line.rotate-1 {
    transform: rotate(45deg);
    margin-top: 0px;
}
.navbar-toggle-line.rotate-2 {
    transform: rotate(-45deg);
    margin-top: -7px;
}
.navbar-toggler,.navbar-toggler:focus{
    outline: none;
    border: 0px;
    box-shadow: none;
}
.navbar-toggler.collapsed span{
transform: rotate(0);
margin-top:0px;
opacity: 1;

}

.list-overflow-item {
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: -2px 3px 2px 1px rgb(0 0 0 / 15%);
    min-height: 150px;
    cursor: pointer;
}

.list-overflow-item h4{
    font-size: 18px;
    font-weight: 600;
}

.list-overflow-item figure{
    border-radius: 50%;
    overflow: hidden;
    width: 125px;
    height: 125px;
    border: 1px solid #7c8085;
} 

.list-overflow-item figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.scroll-bar {
    scrollbar-width: thin;
    scrollbar-color: #00004e #ffffff;
}

.scroll-bar::-webkit-scrollbar {
    width: 8px;
}

.scroll-bar::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
}

.scroll-bar::-webkit-scrollbar-thumb {
    background-color: #00004e;
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.scroll-bar::-webkit-scrollbar-thumb:hover {
    background-color: #000080;
}

.list-overflow-item.list-active{
    background: linear-gradient(45deg, #0081aa, #971e1e);
    color: #fff;
}
.category-card{
    border-radius: 10px;
}
.category-card .card-img-top{
    height: 180px;
    text-align: center;
}
.category-card .card-img-top img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-SemiBoldItalic.bc75c35b.woff) format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-BoldItalic.9b1cbf48.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-Italic.d61329dc.woff) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-Bold.0d9c6d97.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-SemiBold.b09dc729.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-MediumItalic.ef2779e4.woff) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alata';
    src: url(/static/media/Alata-Regular.2e6ead39.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-Regular.35f1a1c0.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Asap';
    src: url(/static/media/Asap-Medium.d0626ef5.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



/* @import "./fonts.css"; */
/* @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"; */
/* @import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;800&display=swap"; */
/* @import "./bootstrap/bootstrap.min.css"; */

